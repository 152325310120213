import React from 'react'
import SiteNavigation from './siteNavigation'
import PropTypes from 'prop-types'

const ElegantHeader = props => (
  <>
    <SiteNavigation />
    <div className='maingrad pt-24 pb-20'>
      <div className='section'>
        <div className='md:flex md:items-center md:justify-between'>
          <div className='md:w-1/2'>
            <h1 className='font-semibold text-3xl md:text-5xl text-white'>{props.title}</h1>
            <h2 className='text-xl font-semibold py-8'>{props.subtitle}</h2>
            {props.buttonTitle &&(
            <a href={props.buttonLink} className='whiteBtn mt-12' title={props.buttonTitleTag}>{props.buttonTitle}</a>
            )}
          </div>
          <div className='md:w-1/2 text-right'>
            {props.image}
          </div>
        </div>
      </div>
    </div>
  </>
)

ElegantHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.object
}

export default ElegantHeader
