import React, { useState, useRef, useEffect } from 'react';
import { graphql } from 'gatsby'
import Section16 from '../components/sections/section16'
import CommonFooter from '../components/footer/commonFooter'
import SiteNavigation from '../components/header/siteNavigation'
import SvgIndustries from '../illustrations/Industries/Industries'
import Seo from '../components/seo/seo'
import { useTranslation } from 'react-i18next'
import ElegantHeader from '../components/header/elegantHeader'

const QS = ({ data }) => {
  const { t, i18n } = useTranslation();

  // Current language
  const currentLanguage = i18n.language;

  return (
    <>
    <SiteNavigation />
    <div className='maingrad pt-24 pb-12'>
        <p className='text-center text-3xl font-semibold text-white'>{t('satisfactionSurvey.title')}</p>
    </div>
    <div className='md:max-w-5xl md:mx-auto px-8 md:px-0 py-12'>
        {currentLanguage === 'it' && (
        <iframe width="100%" height="1600" src="https://www.chatsurvey.io/embed/biuhkwonfz" frameborder="0" title="Questionario Soddisfazione Clienti"></iframe>
        )}
        {currentLanguage === 'en' && (
        <iframe width="100%" height="1600" src="https://www.chatsurvey.io/embed/Tgb906RWok" frameborder="0" title="Customers Satisfaction Surbey"></iframe>  
        )}
        {currentLanguage === 'es' && (
        <iframe width="100%" height="1600" src="https://www.chatsurvey.io/embed/g1EkMydXxl" frameborder="0" title="Customers Satisfaction Surbey"></iframe>  
        )}
    </div>
    <CommonFooter />
    </>
  ) 
}

export default QS;